import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { FormattedMessage } from 'react-intl';

import { Button, Stack, StackProps, Typography } from '@mui/material';

import { ROUTES } from '@arrived/common';

export const EmailStart = ({ sx, ...rest }: StackProps) => {
  const router = useRouter();

  const handleGetStartedClick = useCallback(() => {
    router.push(ROUTES.public.register);
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      gap={{
        xs: 2,
        md: 2,
      }}
      minWidth="75%"
      py={{
        xs: 6,
        md: 9.25,
      }}
      px={2.5}
      mx={{
        xs: 1.25,
        md: 6.25,
      }}
      mt={{
        xs: 6,
        md: 14,
      }}
      mb={{
        xs: 3,
        md: 6,
      }}
      borderRadius="10px"
      sx={{
        background: ({ gradients }) => gradients.yin.vertical,
        ...sx,
      }}
      {...rest}
    >
      <Typography
        variant="h3.semibold"
        color={({ palette }) => palette.neutrals.white}
        sx={{
          fontSize: {
            xs: '1.875rem',
          },
          lineHeight: {
            xs: '1.875rem',
          },
        }}
      >
        <FormattedMessage id="homepage-v2.email-sign-up.title" />
      </Typography>
      <Button color="secondary" onClick={handleGetStartedClick}>
        <FormattedMessage id="landing-page.get-started" defaultMessage="Get Started" />
      </Button>
    </Stack>
  );
};
